import axios from "@/lib/axios";
import { defineStore } from "pinia";

interface State {
	messages: Message[];
	waitingResponse: boolean;
}

export interface Message {
	id: number;
	text: string;
	isQuestion: boolean;
}

export const useChatbotStore = defineStore("chatbot", {
	state: (): State => ({
		messages: [],
		waitingResponse: false,
	}),

	actions: {
		async getStreamResponse(query: string, msgId: number) {
			await axios
				.post(
					"/chatbot/response",
					{
						query,
						stream: true,
					},
					{
						headers: {
							Accept: "text/event-stream",
						},
						responseType: "stream",
						adapter: "fetch",
					},
				)
				.then(async (response) => {
					const stream = response.data;

					// consume response
					const reader = stream
						.pipeThrough(new TextDecoderStream())
						.getReader();
					while (true) {
						const { value, done } = await reader.read();
						if (done) break;
						if (value) {
							for (let i = 0; i < this.messages.length; i++) {
								if (this.messages[i].id === msgId) {
									this.messages[i].text += value.replace(/\n/g, "");
								}
							}
						}
					}
				});
		},
	},
});
